<template>
  <div class="container pt-5">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center">{{ $t('translations.contactUs.title') }}</h2>
      </div>
    </div>
  </div>
  <!-- Contact 1 - Bootstrap Brain Component -->
  <div>
    <div class="container">
      <div class="row justify-content-lg-center">
        <div class="col-12 col-lg-9">
          <div class="row m-1 d-flex justify-content-between align-items-center">
            <div class="col-sm-12 col-md-6 d-flex align-items-center info">
              <span class="material-symbols-outlined m-2">mail</span> office@aquaroyal-fiberglass.me
            </div>
            <div class="col-sm-12 col-md-3 d-flex align-items-center info">
              <span class="material-symbols-outlined m-2">location_on</span> {{ $t('translations.common.location') }}
            </div>
            <div class="col-sm-12 col-md-3 d-flex justify-content-md-end align-items-center info">
              <span class="material-symbols-outlined m-2">call</span> <a style="text-decoration: none; color: #E3BC55" href="tel:+38267143040">{{ $t('translations.common.phone') }}</a>
            </div>
          </div>
          <div class="overflow-hidden">

            <form id="form">
              <div class="row gy-4 gy-xl-5 p-4 p-xl-5">
                <div class="col-12">
                  <label for="fullname" class="form-label">{{ $t('translations.contactUs.name') }} <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" id="fullname" name="fullname" value="" required>
                </div>
                <div class="col-12 col-md-6">
                  <label for="email" class="form-label">{{ $t('translations.contactUs.email') }} <span class="text-danger">*</span></label>
                  <div class="input-group">
                  <span class="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                  </span>
                    <input type="email" class="form-control" id="email" name="email" value="" required>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <label for="phone" class="form-label">{{ $t('translations.contactUs.phone') }} <span class="text-danger">*</span></label>
                  <div class="input-group">
                  <span class="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                  </span>
                    <input type="tel" class="form-control" id="phone" name="phone" value="" required>
                  </div>
                </div>
                <div class="col-12">
                  <label for="message" class="form-label">{{ $t('translations.contactUs.message') }} <span class="text-danger">*</span></label>
                  <textarea class="form-control" id="message" name="message" rows="3" required></textarea>
                </div>
                <div class="col-12">
                  <div v-if="errorMessage" class="alert alert-dismissible alert-danger">
                    <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                    <strong>{{ $t(errorMessage) }}</strong>
                  </div>
                  <div v-if="successMessage" class="alert alert-dismissible alert-success">
                    <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                    <strong>{{ $t(successMessage) }}</strong>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-grid">
                    <button @click="sendMessage" id="send-message" class="btn btn-primary btn-lg d-flex justify-content-center align-items-center" type="submit">
                      {{ $t('translations.contactUs.sendButton') }}
                      <span class="material-symbols-outlined m-2">send</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="height: 100px"></div>
</template>



<script>
export default {
  data() {
    return {
      errorMessage: "",
      successMessage: "",
    }
  },
  methods: {
    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    },
    validatePhoneNumber(phoneNumber) {
      const regex = /^[0-9\s\-+()]{8,}$/;
      return regex.test(phoneNumber);
    },
    sendMessage() {
      event.preventDefault();

      const fullname = document.getElementById('fullname').value;
      const email = document.getElementById('email').value;
      const phone = document.getElementById('phone').value;
      const message = document.getElementById('message').value;

      // Reset error and success messages
      this.errorMessage = "";
      this.successMessage = "";

      if (!fullname || !email || !phone || !message) {
        this.errorMessage = "translations.common.notAllFieldsAreFulfilledException";
        return;
      }

      if (!this.validateEmail(email)) {
        this.errorMessage = "translations.common.emailNotValidException";
        return;
      }

      if (!this.validatePhoneNumber(phone)) {
        this.errorMessage = "translations.common.phoneNumberNotValidException";
        return;
      }

      this.$loadScript("https://smtpjs.com/v3/smtp.js").then(() => {
        window.Email.send({
          SecureToken : "68c8db67-7288-4c79-b5d4-c6758d835abc",
          To : "office@aquaroyal-fiberglass.me",
          From : "office@aquaroyal-fiberglass.me",
          Subject : `${email} - Nova poruka`,
          Body : 'Pošiljalac: <a href="mailto:' + email + '?subject=Odgovor na e-mail">' + email + '</a>' + '<br/><br/>' + 'Broj telefona: ' + phone + '<br/><br/>' + 'Tekst poruke:' + '<br/>' + message
        }).then((message) => {
          if (message == "OK") {
            this.successMessage = "translations.common.messageSentSuccessfully";
          } else {
            this.errorMessage = "translations.common.errorOccurred";
          }
        }).catch((error) => {
          console.error(error);
          this.errorMessage = "translations.common.errorOccurred";
        });
      });
    }
  },
  name: 'ContactUsForm'
}
</script>

<style scoped>
  body {
    background: radial-gradient(rgba(255, 254, 234, 1) 0%, rgba(255, 254, 234, 1) 35%, #B7E8EB 100%);
  }

  .info {
    color: white;
  }

  #form {
    background-color: #333333;
    color: white;
    border-color: #E3BC55;
  }

  #send-message:hover {
    z-index: 2000;
    cursor: pointer;
  }

  h1 {
    color: white;
  }

  h2 {
    color: #E3BC55;
  }

  #fullname,
  #email,
  #phone,
  #message,
  #send-message,
  #form
  {
    position: relative;
    z-index: 1;
    /* Add any other styling you need */
  }
</style>
