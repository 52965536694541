import {createI18n} from "vue-i18n";

const translationData = {
    me: {
        translations: {
            common: {
                buttons: {
                    clickForDetails: "Više detalja",
                    navigateToPools: " UŽIVAJTE U RAZGLEDANJU NAŠEG ASORTIMANA ",
                    galleryLookup: " POGLEDAJTE GALERIJU GOTOVIH BAZENA "
                },
                navBar: {
                    language: "ME",
                    home: "POČETNA",
                    pools: "BAZENI",
                    technology: "TEHNOLOGIJA",
                    aboutUs: "O NAMA",
                    contactUs: "KONTAKT"
                },
                location: "Herceg Novi",
                phone: "+382 67143040",
                notAllFieldsAreFulfilledException: "Greška: Nisu uneta sva polja.",
                errorOccurred: "Došlo je do greške prilikom slanja email poruke. Pokušajte ponovo.",
                emailNotValidException: "Uneta e-mail adresa nije validna.",
                phoneNumberNotValidException: "Uneti broj telefona nije validan.",
                messageSentSuccessfully: "Poruka je uspešno poslata.",
            },
            home: {
              design: "dizajn.",
              quality: "kvalitet.",
              vision: "vizija.",
            },
            pools: {
                title: "Bazeni",
                description: "Naši Aqua Royal modeli bazena ističu se po najvišim standardima izrade iz oblasti jahtogradnje, koju smo preneli u bazensku industriju i kao rezultat toga kreirali smo liniju gotovih bazena koji su izrađeni od visokokvalitetnog fiberglass materijala otpornog na sve vremenske uslove.",
                familyCard: " Ovi bazeni predstavljaju vrhunac kako svojim dimenzijama, tako i luksuznim dekorom i udobnošću. Dimenzije Aqua Royal Family bazena su 8 x 3,5 x 1,5 metara. ",
                candyCard: " Aqua Royal Candy je naš model bazena prilagođen za manja dvorišta i porodice koje žele bezbrižno uživanje u svim funkcijama bazena. Aqua Royal Candy je veoma popularan i funkcionalan mini bazen dimenzija 6 x 2,5 x 1,20 m. ",
                relaxSpaCard: " Aqua Royal Relax Spa bazeni su duša svake dobre zabave i opuštanja, a druženje i provod nikad nisu bili bliže Vašem domu. Aqua Royal Relax Spa bazen je dimenzija 3,5 x 2,8 metara i dubine 85 centimetara. ",
                sunbedCard: " Aqua Royal Sunbed bazeni su naša vizija idealnog sunčanja i odmora u privatnosti vašeg doma ili dvorišta. Dimenzije Aqua Royal Sunbed bazena su 2 x 2,4 metara sa dubinom od samo 45 centimetara što ga čini idealnim za ležanje i sunčanje. ",
                text1: "Za izradu naših fiberglass bazena koristimo vrhunske i serifikovane kompozite i repromaterijale vodećih svijetskih proizvođača iz oblasti jahtogradnje, a kao rezultat toga nastali su Aqua Royal bazeni nove generacije.",
                bulletin1: "Otporni na hlor i sve hemijske procese.",
                bulletin2: "Otporni na mraz i sve vremenske uslove.",
                bulletin3: "Otporni na vlagu i osmozu. (Vinilester yaht barijera)",
                bulletin4: "Otporni na lepljenje algi i ostalih mikroorganizama. (GelCoat special pool layer)",
                bulletin5: "Brzo se ugrađuju",
                bulletin6: "Jednostavno se održavaju",
                text2: "Za razliku od tradicionalne metode gradnje bazena koja može potrajati 2 do 3 mjeseca efektivnog rada, Aqua Royal fiberglass bazeni se mogu postaviti za svega nekoliko dana. Ovo samo po sebi predstavlja veliku prednost, jer Aqua Royal bazeni ne zahtijevaju obilne i skupe građevinske radove već stižu na Vašu adresu kao gotov proizvod sa kompletnom filtracionom tehnikom.",
                familyModel: {
                    title: "Family Model",
                    description: "Ovi bazeni predstavljaju vrhunac kako svojim dimenzijama, tako i luksuznim dekorom i udobnošću.",
                    text1: "Za relaksaciju, popodnevni odmor ili prosto uživanje u rashlađujućim efektima Vašeg ličnog Aqua Royal Family bazena predstavljamo Vam ergonomski dizajniranu platformu 3x1m sa klupom za sijedenje unutar bazena koja je anatomski prilagođena da omogući idealan položaj Vašeg tijela kako bi se postigao maksimalan efekat udobnosti.",
                    text2: "Naš tim stručnjaka će se pobrinuti da u skladu sa Vašim potrebama i mogućnostima, Vaše želje postanu realnost Vašeg dvorišta.",
                    text3: "Aqua Royal bazeni dostupni su u tri standarne boje - bijela, plava, i krem. Postoji mogućnost  izrade bazena u boji po vašoj želji pri čemu se produžava rok isporuke.",
                    text4: "Dimenzije Aqua Royal Family bazena su 8 x 3,5 x 1,5 metara i kao takve daju mogućnost za učenje plivanja, zabavu u vodi, kao i cijelokupnu porodičnu relaksaciju.",
                    text5: "Svi Aqua Royal bazeni podržavaju opciju dodatne opreme kao što je izbor između ručnog poluautomatskog i automatskog održavanja, opciju bazena sa slanom vodom, rasvijetne opreme i ostale dodatne opreme.",
                    text6: "Prema Vašim željama, potrebama, i uslovima Aqua Royal bazen se može uraditi nadzidno i ugradno.",
                    text7: "U sklopu Aqua Royal bazena nudimo i usluge pejzažne arhitekture i uređivanja dvorišta oko samog bazena.",
                },
                candyModel: {
                    title: "Candy Model",
                    description: "Aqua Royal Candy je naš model bazena prilagođen za manja dvorišta i porodice koje želebezbrižno uživanje u svim funkcijama bazena.",
                    text1: "Aqua Royal Candy je veoma popularan i funkcionalan mini bazen dimenzija 6 x 2,5 x 1,20 mposebno prilagođen za nadzemnu instalaciju pogodnu za krovove i ravne terase svih vrstao bjekata, kao i za manja dvorišta u kojima je dostupna i ugradna varijanta ovog modela.",
                    text2: "Dubina od 120 centimetara u većem dijelu bazena pogodna je za dijecu i igre u vodi dokunaprijed ugrađena i ergonomski dizajnirana klupa za sijedenje omogućava relaksaciju i uživanje u rashlađujućim efektima, a u isto vrijeme i jednostavan i siguran ulaz u bazen.",
                    text3: "Dostupan u tri standardne boje - bijela, plava, i krem. Postoji mogućnost izrade bazena u boji po vašoj želji pri čemu se produžava rok isporuke.",
                    text4: "Svi naši bazeni podržavaju opciju dodatne opreme kao što je izbor između ručnog,poluautomatskog i automatskog održavanja, opciju bazena sa slanom vodom, rasvijetne opreme i ostale dodatne opreme.",
                    text5: "U sklopu Aqua Royal Candy bazena nudimo i usluge pejzažne arhitekture i uređivanja dvorišta oko samog bazena.",
                },
                relaxSpa: {
                    title: "Relax Spa",
                    description: "Aqua Royal Relax Spa bazeni su duša svake dobre zabave i opuštanja, a druženje i provod nikad nisu bili bliže Vašem domu.",
                    text1: "Aqua Royal Relax Spa bazen je dimenzija 3,5 x 2,8 metara i dubine 85 centimetara za maksimalno iskorištenje manjih prostora.",
                    text2: "Glavna odlika Aqua Royal Relax Spa bazena su dvije naspramne klupe za sjedenje sa mogućnošću dodavanja stola u visini vode između klupa.",
                    text3: "Klupe su posebno dizajnirane da omogućavaju udobno sijedenje i uživanje u vodi do visine grudi.",
                    text4: "Poput klupa, na obije strane, nalaze se i dva stepeništa za jednostavan i siguran ulazak u bazen.",
                    text5: "Dubina od svega 85 centimetara pruža mogućnost i najmlađim članovima Vaše porodice da Vam se bezbijedno pridruže prilikom zabave u bazenu.",
                    text6: "Prema Vašim željama, potrebama, i uslovima Aqua Royal Relax Spa bazen se može uraditi nadzidno i ugradno.",
                    text7: "Aqua Royal bazeni dostupni su u tri standarne boje - bijela, plava, i krem. Postoji mogućnost izrade bazena u boji po Vašoj želji pri čemu se produžava rok isporuke.",
                    text8: "U sklopu Aqua Royal Relax Spa bazena nudimo i usluge pejzažne arhitekture i uređivanja dvorišta oko samog bazena.",
                    text9: "Svi Aqua Royal bazeni podržavaju opciju dodatne opreme kao što je izbor između ručnog, poluautomatskog i automatskog održavanja, opciju bazena sa slanom vodom, rasvijetne opreme i ostale dodatne opreme.",
                },
                sunbed: {
                    title: "Sunbed Bazen",
                    description: "Aqua Royal Sunbed bazeni su naša vizija idealnog sunčanja i odmora u privatnosti vašeg doma ili dvorišta.",
                    text1: "Dimenzije Aqua Royal Sunbed bazena su 2 x 2,4 metara sa dubinom od samo 45 centimetara štoga čini idealnim za ležanje i sunčanje.",
                    text2: "Savremene metode izrade garantuju da će Vaš Aqua Royal Sunbed bazen biti otporan,dugotrajan i prije svega siguran za korišćenje.",
                    text3: "Ergonomski nagib stranice bazena posebno je dizajniran za Vaša leđa i vrat, a za povećanu udobnost i maksimalno opuštanje predlažemo postavljanje ležaljki unutar bazena.",
                    text4: "Konstrukcija i dizajn Aqua Royal Sunbed bazena čine ga najpogodnijim za unutrašnje postavke poput kupatila ili terasa.",
                    text5: "Prema Vašim željama, potrebama, i uslovima Aqua Royal Sunbed bazen se može uraditi nadzidno i ugradno.",
                    text6: "Aqua Royal bazeni dostupni su u tri standarne boje - bijela, plava, i krem. Postoji mogućnost izrade bazena u boji po vašoj želji pri čemu se produžava rok isporuke.",
                    text7: "U sklopu Aqua Royal Sunbed bazena nudimo i usluge pejzažne arhitekture, uređivanja dvorišta,kao i samog oboda bazena.",
                }
            },
            technology: {
                title: "Fiberglass Tehnologija",
                fiberglassCommonTitle: "Šta je fiberglass?",
                fiberglassCommonDescription: "Razvojem Fiberglass tehnologije u svijetu nastali su materijali izuzetnih karakteristika i predstavljaju pravu revoluciju u savremenoj industrijskoj izradi visokokvalitetnih proizvoda od armiranog poliestera, a naročito u jahtogradnji i modernoj građevinskoj, kao i auto-industriji.",
                fiberglassManufacturingTitle: "Kako se pravi fiberglass materijal?",
                fiberglassManufacturingDescription: "Dobija se specijalnim procesom polimerizacije, odnosno višeslojnim spajanjem materijala  različitih fizičkih svojstava u jednu neraskidivu cijelinu, a kao rezultat takve tehnologije nastaje univerzalni, višenamjenski materijal koji se u savremenoj industriji najviše koristi u jahtogradnji, a pored nautike sve je zastupljeniji i u građevinarstvu i automobilskoj industriji.",
                fiberglassCharacteristicsTitle: "Osobine Fiberglass materijala?",
                fiberglassCharacteristicsDescription: {
                    bulletin1: "• Posjeduje odlična termoizolaciona svojstva i visok nivo čvrstoće koji premašuje čvrstoću legiranog čelika i betona, kao i sposobnost zadržavanja originalnog oblika i savršeno odoljeva starenju i deformacijama.",
                    bulletin2: "• Posjeduje visoku otpornost na uvijanja, habanja, fizičke udare, hemikalije, vlagu, mraz, koroziju i sve vremenske uslove.",
                    bulletin3: "• Fiberglass se ne pali, ne gori i ne topi, što ga čini vatrootpornim materijalom.",
                    bulletin4: "• Ekološki je prihvatljiv i potpuno bezbijedan materijal sa višedecenijskim životnim vijekom.",
                },
                poolStructure: "Struktura bazena",
            },
            aboutUs: {
                title: "O nama",
                poolsManufactured: "IZGRAĐENIH BAZENA",
                manufacturingArea: "PROIZVODNE POVRŠINE",
                countries: "DRŽAVA U REGIONU",
                text1: "Savremene fiberglas tehnologije otvorile su novi svijet mogućnosti u izradi visokokvalitetnih plovila i bazena. Aqua Royal je vizija tog novog svijeta bezbednosti, dugotrajnosti i beskonačne zabave. Uplovite sa nama u svijet moderne građevinske umjetnosti dok spajamo vodu i savremenu fiberglass tehnologiju.",
                text2: "Aqua Royal kompanija je zasnovana na viziji elegantnog dizajna i vrhunskog kvaliteta izrade. Da bi smo zaslužili Vaše povjerenje i ostvarili visoke standarde kvaliteta koje smo postavili, mi smo naše povjerenje dali ekspertima iz stručnog tima kompanije Aqua Harmony. Iza imena Aqua Harmony stoji višegodišnje iskustvo u izradi bazena, kao i preciznost i kvalitet zbog kojih su postali sinonim za dugoviječnost.",
                text3: "Naša misija je da Vam kroz proizvode iz našeg Aqua Royal asortimana omogućimo moderni ambijent relaksacije u privatnosti Vašeg dvorišta. Svi naši proizvodi su jedinstveni i dolaze sa višedecenijskom garancijom.",
            },
            contactUs: {
                title: "Kontaktirajte nas!",
                name: "Ime i prezime ",
                email: "E-mail ",
                phone: "Broj telefona",
                message: "Poruka ",
                sendButton: "Pošalji",
            }
        },
    },
    ru: {
        translations: {
            common: {
                buttons: {
                    clickForDetails: "Детали",
                    navigateToPools: " НАСЛАЖДАЙТЕСЬ ПРОСМОТРОМ НАШЕГО АССОРТИМЕНТА ",
                    galleryLookup: " ПОСМОТРЕТЬ ГАЛЕРЕЮ ГОТОВЫХ БАССЕЙНОВ "
                },
                navBar: {
                    language: "РУ",
                    home: "ГЛАВНАЯ",
                    pools: "БАССЕЙНЫ",
                    technology: "О ТЕХНОЛОГИИ",
                    aboutUs: "О НАС",
                    contactUs: "КОНТАКТ"
                },
                location: "Херцег-Нови",
                phone: "+382 67143040",
                notAllFieldsAreFulfilledException: "Ошибка: Не все поля заполнены.",
                errorOccurred: "Произошла ошибка при отправке сообщений электронной почты.",
                emailNotValidException: "Введенный адрес электронной почты недействителен.",
                phoneNumberNotValidException: "Введенный номер телефона недействителен.",
                messageSentSuccessfully: "Сообщение успешно отправлено.",
            },
            home: {
                design: "Дизайн.",
                quality: "Качество.",
                vision: "Визионерство.",
            },
            pools: {
                title: "Бассейны",
                description: "Наши модели бассейнов Aqua Royal отличаются высшими стандартами изготовления,применяемыми в строительстве яхт, которые мы перенесли на производство бассейнов. В результате мы получили линейку готовой продукции из стекловолокна высокогокачества, устойчивого к любым условиям погоды.",
                familyCard: " Модели бассейнов Family по своим размерам, по декору класса люкс и комфорту представляют собой премиальную продукцию. ",
                candyCard: " Aqua Royal Candy, это наша модель бассейна для небольших дворов, она предназначена для тех, кто желает пользоваться всеми функциями бассейна. ",
                relaxSpaCard: " Aqua Royal Relax Spa бассейны, это ключ к успеху каждой вечеринки и отдыха. Веселье и дружба еще никогда не находились так близко к Вашему дому. ",
                sunbedCard: " Aqua Royal Sunbed бассейны отражают наше видение идеального отдыха и загорания на солнце в уединении Вашего дома или двора. ",
                text1: "В производстве наших бассейнов пользуемся композитами и материалами высшегокачества ведущих мировых производителей, используемых в основном для строительстваяхт, а в результате у нас появились бассейны нового поколения под названием Aqua Royal, которые",
                bulletin1: "Устойчивы к хлору и всем химическим веществам",
                bulletin2: "Устойчивы к морозу и любой погоде",
                bulletin3: "Устойчивы к влаге и осмосу (винилэстер – осмотический яхт барьер)",
                bulletin4: "Устойчивы к прилипанию водорослей и микроорганизмов (GelCoat special pool layer)",
                bulletin5: "Быстрая установка",
                bulletin6: "Простое техническое обслуживание",
                text2: "В отличие от традиционных методов по строительству бассейнов, требующих 2-3 месяца эффективной работы, установка Aqua Royal бассейнов заканчивается всего через несколькодней. Это огромное достоинство само по себе, потому что Aqua Royal бассейны не требуют проведения объемных и затратных строительных работ. Вместо того, готовый бассейнприходит на Ваш адрес вместе с полным набором комплектующей фильтрационной техники.",
                familyModel: {
                    title: "Модель Family",
                    description: "Модели бассейнов Family по своим размерам, по декору класса люкс и комфорту представляют собой премиальную продукцию.",
                    text1: "Для релаксации, отдыха или только расслабления благодаря охлаждающим эффектам Вашего персонального Aqua Royal Family бассейна представляем Вам эргономический дизайн платформы 3,0 х 1,0 м со скамьей для сидения, специально анатомически спроектированной для идеального положения тела и максимального комфорта.",
                    text2: "Наша команда экспертов позаботится о ваших потребностях и возможностях для того, чтобы Ваши пожелания стали реальностью именно на Вашем дворе.",
                    text3: "Бассейны Aqua Royal доступны в трех стандартных цветовых решениях: белом, синем и бежевом. Имеется также возможность выбора других цветов на заказ, по Вашему желанию, но в таком случае сроки доставки увеличиваются.",
                    text4: "Aqua Royal Family бассейны размером 8 x 3,5 x 1,5 годны как для курсов плавания, так и для развлечения в воде и спортивных занятий всей семьи.",
                    text5: "Все бассейны Aqua Royal поддерживают опцию дополнительного оборудования, в частности выбора обслуживания и ухода – (ручное, полуавтоматическое или автоматическое), бассейны могут быть с морской водой, возможна установка фонарей и прочего дополнительного оборудования.",
                    text6: "В зависимости от Ваших пожеланий, требований и условий, бассейны Aqua Royal Family могут быть надземными и встроенными.",
                    text7: "В рамках Aqua Royal Family предлагаем также услуги по ландшафтной архитектуре и благоустройству территории непосредственно вокруг бассейна.",
                },
                candyModel: {
                    title: "Модель Candy",
                    description: "Aqua Royal Candy, это наша модель бассейна для небольших дворов, она предназначена для тех, кто желает пользоваться всеми функциями бассейна.",
                    text1: "Aqua Royal Candy, это очень популярный и функциональный мини бассейн размером 6 x 2,5 x 1,20 м, специально обустроен для надземной установки на крышах и террасах на всех типах объектов, равно как и для небольших дворов (для которых доступен также встраиваемый вариант той же модели).",
                    text2: "В большей части глубина этого бассейна составляет 120 см, что очень хорошо для детей, для всех видов развлечений в воде, а заранее встроенная и эргономически спроектированная скамейка дает возможность отдохнуть и расслабиться в бассейне с простым и безопасным спуском и охлаждающими эффектами.",
                    text3: "Бассейны Candy доступны в трех стандартных цветовых решениях: белом, синем и бежевом. Имеется также возможность выбора других цветов на заказ, по Вашему желанию, но в таком случае сроки доставки увеличиваются.",
                    text4: "Все бассейны Aqua Royal поддерживают опцию дополнительного оборудования, в частности выбора обслуживания и ухода – (ручное, полуавтоматическое или автоматическое), бассейны могут быть с морской водой, возможна установка фонарей и прочего дополнительного оборудования.",
                    text5: "В рамках Aqua Royal Candy бассейнов предлагаем также услуги по ландшафтной архитектуре и благоустройству территории непосредственно вокруг бассейна.",
                },
                relaxSpa: {
                    title: "Модель Relax Spa",
                    description: "Aqua Royal Relax Spa бассейны, это ключ к успеху каждой вечеринки и отдыха. Веселье и дружба еще никогда не находились так близко к Вашему дому.",
                    text1: "Aqua Royal Relax Spa бассейны размером 3,5 x 2,8 метров и глубиной 85 см предназначены для максимального использования небольшой площади.",
                    text2: "Главная характеристика бассейнов Aqua Royal Relax Spa, это две противоположные скамьи для сидения, между ними можно также установить стол, высота которого соответствует уровню воды.",
                    text3: "Скамьи специально спроектированы, чтобы обеспечить комфорт и отдых в воде, уровень которой достигает высоту груди.",
                    text4: "С обеих сторон находятся ступеньки для простого и безопасного спуска в бассейн.",
                    text5: "Глубина бассейна составляет 85 см, что дает возможность для самых маленьких членов Вашей семьи присоединиться и развлекаться в бассейне вместе с Вами.",
                    text6: "В зависимости от Ваших пожеланий, требований и условий, бассейны Aqua Royal Relax Spa могут быть надземными и встроенными.",
                    text7: "Бассейны Aqua Royal Relax Spa доступны в трех стандартных цветовых решениях: белом, синем и бежевом. Имеется также возможность выбора других цветов на заказ, по Вашему желанию, но в таком случае сроки доставки увеличиваются.",
                    text8: "В рамках Aqua Royal Relax Spa предлагаем также услуги по ландшафтной архитектуре и благоустройству территории непосредственно вокруг бассейна.",
                    text9: "Все бассейны Aqua Royal поддерживают опцию дополнительного оборудования, в частности выбора обслуживания и ухода – (ручное, полуавтоматическое или автоматическое), бассейны могут быть с морской водой, возможна установка фонарей и прочего дополнительного оборудования.",
                },
                sunbed: {
                    title: "Sunbed Bazen",
                    description: "Aqua Royal Sunbed бассейны отражают наше видение идеального отдыха и загорания на солнце в уединении Вашего дома или двора.",
                    text1: "Размер бассейнов Aqua Royal Sunbed составляет 2,0 x 2,4 м, а его глубина составляет всего 45 см, поэтому он идеально подходит для пребывания и загорания на солнце.",
                    text2: "Современная методология гарантирует Вам не только безопасность, а и прочность, и долговечность Вашего Aqua Royal Sunbed бассейна.",
                    text3: "Угол наклона спинки бассейна спроектирован для эргономического положения спины и шеи. Дополнительно предлагаем Вам увеличить уровень комфорта и отдыхать в Вашем бассейне на установленных внутри самого бассейна шезлонгах.",
                    text4: "По своей конструкции и дизайну Aqua Royal Sunbed бассейны идеальны для внутреннего использования в ванных и на террасах.",
                    text5: "В зависимости от Ваших пожеланий, требований и условий, бассейны Aqua Royal Sunbed могут быть надземными и встроенными.",
                    text6: "Бассейны Aqua Royal Sunbed доступны в трех стандартных цветовых решениях: белом, синем и бежевом. Имеется также возможность выбора других цветов на заказ, по Вашему желанию, но в таком случае сроки доставки увеличиваются.",
                    text7: "В рамках Aqua Royal Relax Spa предлагаем также услуги по ландшафтной архитектуре и лагоустройству территории непосредственно вокруг бассейна.",
                }
            },
            technology: {
                title: "Стекловолокно технологии",
                fiberglassCommonTitle: "Что же такое стекловолокно?",
                fiberglassCommonDescription: "Развитие технологии производства стекловолокна приносит с собой начало использования материалов чрезвычайных характеристик, что представляет собой настоящую революцию в области современной продукции высокого качества из армированного полиэстера, а именно в современной строительной, судостроительной и автомобильной промышленностях.",
                fiberglassManufacturingTitle: "Как производится материал из стекловолокна?",
                fiberglassManufacturingDescription: "Он получается специальным процессом полимеризации, в частности соединением в одно целое многочисленных слоев материалов, обладающих различными физическими свойствами, и в результате получается универсальный, многофункциональный материал, который в наше время больше всего используется для строения яхт. Также находит применение в строительной и автомобильной промышленностях.",
                fiberglassCharacteristicsTitle: "Качества материалов из стекловолокна?",
                fiberglassCharacteristicsDescription: {
                    bulletin1: "• Отличаются превосходными теплоизоляционными свойствами, высокой прочностью на сжатие (превышающей прочность легированной стали и бетона), удерживают оригинальную форму и преодолевают времени и деформациям.",
                    bulletin2: "• Они же устойчивы к скручиванию, износу, физическим воздействиям, химическим веществам, влажности, морозу, коррозии и любой погоде.",
                    bulletin3: "• Стекловолокно устойчиво к воспламенению, горению и плавлению, соответственно, речь идет об огнеупорном материале.",
                    bulletin4: "• Стекловолокно является экологический устойчивым и полностью безопасным материалом, со сроком эксплуатации до нескольких десятков лет.",
                },
                poolStructure: "Структура бассейна",
            },
            aboutUs: {
                title: "О нас",
                poolsManufactured: "ПОСТРОЕНО БАССЕЙНОВ",
                manufacturingArea: "ПРОИЗВОДСТВЕННАЯ ПЛОЩАДЬ",
                countries: "СТРАНА В РЕГИОНЕ",
                text1: "Современные технологии производства стекловолокна открыли весь мир возможностей по производству высококачественных суден и бассейнов. Aqua Royal является визионером в новом мире безопасности, долговечной продукции и безграничного удовольствия. Предлагаем Вам вместе с нами открыть мир искусства современного строительства и соединить воду с современной технологией производства стекловолокна.",
                text2: "Деятельность компании Aqua Royal исходит из визионерского сочетания элегантного дизайна с наилучшим качеством продукции. Чтобы добиться Вашего доверия и достичь высокого уровня качества, мы положились на экспертов команды компании Aqua Harmony. За этим именем стоит многолетний опыт в строительстве бассейнов, пунктуальность и качество, оно же является синонимом к долговечной продукции.",
                text3: "Наше задание – обеспечить Вам современное окружение для отдыха и расслабления в уюте Вашего двора с помощью Aqua Royal продукции. Наша продукция является совсем уникальной, при этом гарантируем качество товара в течение не менее десяти лет.",
            },
            contactUs: {
                title: "Связаться с нами!",
                name: "Имя и фамилия ",
                email: "Электронная почта ",
                phone: "Номер телефона ",
                message: "Сообщение ",
                sendButton: "Отправлять",
            }
        },
    },
    en: {
        translations: {
            common: {
                buttons: {
                    clickForDetails: "More details",
                    navigateToPools: " ENJOY BROWSING OUR ASSORTMENT ",
                    galleryLookup: " BROWSE THE GALLERY OF FINISHED POOLS "
                },
                navBar: {
                    language: "EN",
                    home: "HOME",
                    pools: "POOLS",
                    technology: "TECHNOLOGY",
                    aboutUs: "ABOUT US",
                    contactUs: "CONTACT US"
                },
                location: "Herceg Novi",
                phone: "+382 67143040",
                notAllFieldsAreFulfilledException: "Error: Not all fields have been entered.",
                errorOccurred: "An error occurred while sending the e-mail message. Please try again.",
                emailNotValidException: "The e-mail address is not valid.",
                phoneNumberNotValidException: "The phone number is not valid.",
                messageSentSuccessfully: "Message sent successfully.",
            },
            home: {
                design: "design.",
                quality: "quality.",
                vision: "inspiration.",
            },
            pools: {
                title: "Pools",
                description: "Our Aqua Royal pools are made in accordance with the highest standards in boat manufacturing which\n" +
                    "we have translated into the pool industry and as a result, we created a line of pre-made pools which are\n" +
                    "built from high-quality fiberglass resistant to all weather conditions.",
                familyCard: " These pools represent the pinnacle with their measurements as well as luxurious decor and\n" +
                    "comfortability. The measurements of the Aqua Royal Family pool are 8 x 3.5 x 1.5 meters. ",
                candyCard: " Aqua Royal Candy is our model which is most suitable for smaller backyards and families that want to\n" +
                    "enjoy a carefree experience in all the functionalities of a pool. Aqua Royal Candy is a very popular mini pool measuring 6 x 2.5 x 1,20m. ",
                relaxSpaCard: " Aqua Royal Relax Spa pools are the soul of every good party or relaxation because having fun and\n" +
                    "partying has never been closer to your home. Aqua Royal Relax Spa pool is 3.5 by 2.8 meters with a depth of 85 centimeters. ",
                sunbedCard: " Aqua Royal Sunbed pools are our vision of ideal sunbathing and resting within the privacy of your home\n" +
                    "or backyard.Aqua Royal Sunbed pools are 2 by 2.4 meters with a depth of 45 centimeters. ",
                text1: "To make our fiberglass pools we use top-of-the-line, certified composites, and processed materials from\n" +
                    "leading world manufacturers in the area of boat manufacturing which gave us the end result of our new\n" +
                    "generation of Aqua Royal pools.",
                bulletin1: "Resistant to chlorine and all chemical processes.",
                bulletin2: "Resistant to frost and other weather conditions.",
                bulletin3: "Resistant to humidity and osmosis (vinyl ester yacht barrier).",
                bulletin4: "Resistant to algae and other microorganisms adhesion (GelCoat special pool layer).",
                bulletin5: "Quick to install.",
                bulletin6: "Easy to maintain.",
                text2: "Unlike the traditional method of pool building which can last up to 2 to 3 months of effective labor,\n" +
                    "Aqua Royal pools are installable in just a couple of days. This makes for a huge advantage in and of itself\n" +
                    "because Aqua Royal pools do not require extensive and expensive construction works but they come to\n" +
                    "your address as a finished product with all the filtration devices.",
                familyModel: {
                    title: "Family Model",
                    description: "These pools represent the pinnacle with their measurements as well as luxurious decor and\n" +
                        "comfortability.",
                    text1: "For relaxing, afternoon rests, or simply for enjoying the cooling effects of your personal Aqua Royal\n" +
                        "Family pool, we present you the ergonomically designed platform 3x1m with a seating bench inside the\n" +
                        "pool that is anatomically adjusted to enable the ideal position of your body to achieve the maximum\n" +
                        "effect of comfortability.",
                    text2: "Our team of experts will make sure that, per your needs and capabilities, your desires become the\n" +
                        "reality of your backyard.",
                    text3: "Aqua Royal pools come in three standard colors – white, blue, and cream. There is a possibility of\n" +
                        "making the pool in any color you would like but it postpones the delivery date.",
                    text4: "The measurements of the Aqua Royal Family pool are 8 x 3,5 x 1,5 meters and as such they provide the\n" +
                        "possibility of swimming lessons, all kinds of fun in the water as well as the entire family's relaxation.",
                    text5: "All Aqua Royal pools have the option of additional equipment such as the choice between manual\n" +
                        "semiautomatic and automatic maintenance, saltwater pools, lighting, and various other additional\n" +
                        "equipment.",
                    text6: "In accordance with your desires, needs, and conditions, Aqua Royal pools can be installed in the ground\n" +
                        "or above it.",
                    text7: "In addition to the Aqua Royal Family pool, we also offer landscaping and backyard decorating services\n" +
                        "around the pool.",
                },
                candyModel: {
                    title: "Candy Model",
                    description: "Aqua Royal Candy is our model which is most suitable for smaller backyards and families that want to\n" +
                        "enjoy a carefree experience in all the functionalities of a pool.",
                    text1: "Aqua Royal Candy is a very popular and highly functional mini pool measuring 6 x 2.5 x 1.20m especially\n" +
                        "made for above-ground installation and is suitable for roofs and terraces of all kinds of buildings, as well\n" +
                        "as smaller backyards where an in-ground variant of this model is also available.",
                    text2: "The depth of 120cm in most of the pool is suited to children and in-water games while a pre-installed,\n" +
                        "ergonomically designed seating bench allows for relaxation and enjoinment in the cooling effects all the\n" +
                        "while being a safe and simple entry point into the pool.",
                    text3: "Aqua Royal pools come in three standard colors – white, blue, and cream. There is a possibility of\n" +
                        "making the pool in any color you would like but it postpones the delivery date.",
                    text4: "All Aqua Royal pools have the option of additional equipment such as the choice between manual\n" +
                        "semiautomatic and automatic maintenance, saltwater pools, lighting, and various other additional\n" +
                        "equipment.",
                    text5: "In addition to the Aqua Royal Candy pool, we also offer landscaping and backyard decorating services\n" +
                        "around the pool.",
                },
                relaxSpa: {
                    title: "Relax Spa",
                    description: "Aqua Royal Relax Spa pools are the soul of every good party or relaxation because having fun and\n" +
                        "partying has never been closer to your home.",
                    text1: "Aqua Royal Relax Spa pool is 3.5 by 2.8 meters with a depth of 85 centimeters for the maximum\n" +
                        "utilization of smaller spaces.",
                    text2: "The main feature of the Aqua Royal Relax Spa pools is two oppositely placed seating benches with the\n" +
                        "possibility of adding a table in between at the water's height.",
                    text3: "The benches are specially designed to enable comfortable sitting with the water being at chest height.",
                    text4: "Similarly to the benches, there are two sets of stairs on opposite sides of the pool to effortlessly and\n" +
                        "safely enter the pool.",
                    text5: "The depth of only 85 centimeters allows the youngest members of your family to safely join you in\n" +
                        "having fun in the pool.",
                    text6: "In accordance with your desires, needs, and conditions, the Aqua Royal Relax Spa pool can be installed\n" +
                        "in the ground or above it.",
                    text7: "Aqua Royal pools come in three standard colors – white, blue, and cream. There is a possibility of\n" +
                        "making the pool in any color you would like but it postpones the delivery date.",
                    text8: "In addition to the Aqua Royal Relax Spa pool, we also offer landscaping and backyard decorating services\n" +
                        "around the pool.",
                    text9: "All Aqua Royal pools have the option of additional equipment such as the choice between manual\n" +
                        "semiautomatic and automatic maintenance, saltwater pools, lighting, and various other additional\n" +
                        "equipment.",
                },
                sunbed: {
                    title: "Sunbed Bazen",
                    description: "Aqua Royal Sunbed pools are our vision of ideal sunbathing and resting within the privacy of your home\n" +
                        "or backyard.",
                    text1: "Aqua Royal Sunbed pools are 2 by 2.4 meters with a depth of only 45 centimeters which makes it perfect\n" +
                        "for lying down or sunbathing.",
                    text2: "State-of-the-art manufacturing methods ensure that your Aqua Royal Sunbed pool will be resistant,\n" +
                        "long-lasting, and, above all, very safe to use.",
                    text3: "The ergonomic incline of the pool is specially designed for your back and neck and for additional support\n" +
                        "and maximum relaxation, we suggest placing a deck chair inside the pool.",
                    text4: "The design of the Aqua Royal Sunbed pool makes it the most suitable for interior installations such as\n" +
                        "bathrooms or terraces.",
                    text5: "In accordance with your desires, needs, and conditions, the Aqua Royal Sunbed pool can be installed in\n" +
                        "the ground or above it.",
                    text6: "Aqua Royal pools come in three standard colors – white, blue, and cream. There is a possibility of\n" +
                        "making the pool in any color you would like but it postpones the delivery date.",
                    text7: "In addition to the Aqua Royal Relax Spa pool, we also offer landscaping and backyard decorating services\n" +
                        "around the pool.",
                }
            },
            technology: {
                title: "Fiberglass Technology",
                fiberglassCommonTitle: "What is fiberglass?",
                fiberglassCommonDescription: "With the development of Fiberglass technology in the world materials of exceptional properties have\n" +
                    "been made and they pose a real revolution in the modern industrial production of high-quality products\n" +
                    "made of reinforced polyester, especially in boat manufacturing, modern civil engineering, and\n" +
                    "automotive industry.",
                fiberglassManufacturingTitle: "How is Fiberglass made?",
                fiberglassManufacturingDescription: "It is made with a special polymerization process, i.e. a multi-layered adherence of materials with various\n" +
                    "\n" +
                    "physical properties into one indivisible entity and the result of this technology is a universal, multi-\n" +
                    "purpose material that found its use in modern industries mainly in boat manufacturing, but other than\n" +
                    "\n" +
                    "the nautical industry it has also become more frequent in civil engineering and automotive industry.",
                fiberglassCharacteristicsTitle: "Properties of Fiberglass?",
                fiberglassCharacteristicsDescription: {
                    bulletin1: "• It possesses excellent thermal insulation properties and a high level of hardness that is even\n" +
                        "greater than that of alloy steel or concrete, as well as the ability to maintain its original shape\n" +
                        "with perfect resistance to aging and deformations.",
                    bulletin2: "• It is highly resistant to twisting, wear, physical impacts, chemicals, humidity, frost, and corrosion\n" +
                        "in all weather conditions.",
                    bulletin3: "• Fiberglass is inflammable and does not burn nor melt which makes it a fireproof material.",
                    bulletin4: "• It is an ecologically acceptable and entirely safe material with a multi-decennial life span.",
                },
                poolStructure: "Pool Structure",
            },
            aboutUs: {
                title: "About Us",
                poolsManufactured: "POOLS BUILT",
                manufacturingArea: "PRODUCTION AREA",
                countries: "COUNTRIES IN THE REGION",
                text1: "Modern fiberglass technologies have opened up a whole new world of possibilities in the production of high-quality boats and pools. Aqua Royal is the vision of that new world of safety, longevity, and infinite fun. Sail with us into the world of modern construction art as we merge water and state-of-the-art fiberglass technology.",
                text2: "Aqua Royal Company is based on the vision of elegant design and top manufacturing quality. In order to earn your trust and come through with the high standards of quality we imposed on ourselves, we have put our trust into the capable hands of a team of experts from the Aqua Harmony Company. The name Aqua Harmony stands for years of experience in pool manufacturing, as well as the precision and quality that have made them the synonym for endurance.",
                text3: "Our mission is to enable you a modern ambiance of relaxation within the privacy of your home through our line of Aqua Royal products. All of our products are unique and come with a multi-decennial warranty.",
            },
            contactUs: {
                title: "Contact Us!",
                name: "Full Name ",
                email: "E-mail ",
                phone: "Phone Number ",
                message: "Message ",
                sendButton: "Send",
            }
        },
    },
}

const i18n = createI18n({
    locale: 'me',
    fallbackLocale: 'me',
    messages: translationData
});

export default i18n;