<template>
<!--  <div class="container-fluid">-->
<!--    <div class="text-center">-->
<!--      <img src="../../assets/title.png" style="max-width: 350px" />-->
<!--    </div>-->
<!--    <br/>-->
<!--    <div class="container text-center">-->
<!--      <span id="main-text" class="text-center">-->
<!--      Savremene fiberglas tehnologije otvorile su novi svet mogućnosti u izradi visokokvalitetnih plovila i bazena.-->
<!--            <span id="aqua-royal">Aqua Royal</span> je vizija tog novog sveta bezbednosti, dugotrajnosti i beskonačne zabave. Uplovite sa nama u svet moderne građevinske umetnosti dok spajamo vodu i savremenu fiberglass tehnologiju.-->
<!--      </span>-->

<!--      <div class="container mt-3 d-flex justify-content-center align-items-center">-->
<!--        <button style="z-index: 1;" class="btn btn-lg btn-primary d-flex align-items-center" @click="onButtonClick">-->
<!--          UŽIVAJTE U RAZGLEDANJU NAŠEG ASORTIMANA-->
<!--          <span class="material-symbols-outlined m-2">pool</span>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="container pt-5">
    <div class="row text-center d-flex justify-content-center">
          <div class="text-center">
            <img src="@/assets/title.png" style="max-width: 350px" />
          </div>
    </div>
    <div class="row text-center d-flex justify-content-center mt-5 p-3">
      <div class="col-sm-12 col-md-3">
        <h2 class="mt-3">{{ $t('translations.home.design') }}</h2>
      </div>
      <div class="col-sm-12 col-md-3">
        <h2 class="mt-3">{{ $t('translations.home.quality') }}</h2>
      </div>
      <div class="col-sm-12 col-md-3">
        <h2 class="mt-3">{{ $t('translations.home.vision') }}</h2>
      </div>
    </div>
    <div class="container mt-5 d-flex justify-content-center align-items-center">
      <button style="z-index: 1;" class="btn btn-lg btn-primary d-flex align-items-center" @click="onButtonClick">
        {{ $t('translations.common.buttons.navigateToPools') }}
        <span class="material-symbols-outlined m-2">pool</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onButtonClick() {
      this.$router.push({ name: 'Pools' });
    }
  },
  name: 'HomeComponent'
}
</script>

<style scoped>
/* You can also add custom styles here */

.card {
  background: transparent;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  color: #E3BC55;
}

h2 {
  color: #E3BC55;
}

@keyframes zoomOut {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

.service-card {
  animation: zoomOut 0.5s ease;
  height: 130px;
  color: white;
  z-index: 1;
}

.service-card:hover {
  cursor: pointer;
  color: #E3BC55;
}
</style>
