<template>
  <div class="container pt-5">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center">{{ $t('translations.technology.title') }}</h2>
      </div>
    </div>
  </div>

  <div class="container pt-5 d-flex justify-content-between align-items-center">
    <div class="row">
      <div class="col-md-12">
        <div class="main-timeline">
          <div class="timeline">
            <div class="timeline-content">
              <div class="timeline-icon">

              </div>
              <span class="timeline-year">
                <span class="material-symbols-outlined">
                  question_mark
                </span>
              </span>
              <h3 class="title">{{ $t('translations.technology.fiberglassCommonTitle') }}</h3>
              <p class="description">
                {{ $t('translations.technology.fiberglassCommonDescription') }}
              </p>
            </div>
          </div>
          <div class="timeline">
            <div class="timeline-content">
              <div class="timeline-icon">

              </div>
              <span class="timeline-year">
                <span class="material-symbols-outlined">
                  precision_manufacturing
                </span>
              </span>
              <h3 class="title">{{ $t('translations.technology.fiberglassManufacturingTitle') }}</h3>
              <p class="description">
                {{ $t('translations.technology.fiberglassManufacturingDescription') }}
              </p>
            </div>
          </div>
          <div class="timeline">
            <div class="timeline-content">
              <div class="timeline-icon">

              </div>
              <span class="timeline-year">
                <span class="material-symbols-outlined">
                  format_list_bulleted
                </span>
              </span>
              <h3 class="title">{{ $t('translations.technology.fiberglassCharacteristicsTitle') }}</h3>
              <p class="description">
                {{ $t('translations.technology.fiberglassCharacteristicsDescription.bulletin1') }}
              </p>
              <p class="description">
                {{ $t('translations.technology.fiberglassCharacteristicsDescription.bulletin2') }}
              </p>
              <p class="description">
                {{ $t('translations.technology.fiberglassCharacteristicsDescription.bulletin3') }}
              </p>
              <p class="description">
                {{ $t('translations.technology.fiberglassCharacteristicsDescription.bulletin4') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0 mt-3 d-flex justify-content-center align-items-center">
        <div class="text-sm-start text-md-center">
          <h2>{{ $t('translations.technology.poolStructure') }}</h2>
          <br/>
          <div class="text-center">
            <img src="@/assets/fiberglass/layers.png" alt="Pool Layers"
                 style="width: 100%; height: 100%; object-fit: cover;"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="height: 200px">

  </div>
</template>

<script>
export default {
  name: 'TechnologyComponent'
}
</script>

<style scoped>
h2 {
  color: #E3BC55;
}

.material-symbols-outlined {
  font-size: 50px;
}

.main-timeline{
  position: relative;
}
.main-timeline:after{
  content: '';
  display: block;
  clear: both;
}
.main-timeline:before{
  content: '';
  height: 95%;
  width: 2px;
  border: 2px dashed #a3a3ad;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 30px;
}
.main-timeline .timeline{
  width: 50%;
  padding: 0 35px 0 27px;
  margin: 0 10px 20px 0;
  float: left;
}
.main-timeline .timeline-content{
  background-image: url('@/assets/fiberglass/fiberglass2.png'); /* Set your image path here */
  background-size: cover; /* Cover the entire card */
  background-position: center; /* Center the image */
  text-align: center;
  padding: 15px 115px 15px 40px;
  box-shadow: 0 0 10px -5px rgba(0,0,0,0.6);
  display: block;
  position: relative;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-content:before,
.main-timeline .timeline-content:after{
  content: "";
  background: linear-gradient(to bottom,#008cba,#007da6);
  width: 45px;
  height: 45px;
  transform: rotate(-45deg);
  position: absolute;
  top: 13px;
  right: -58px;
}
.main-timeline .timeline-content:after{
  background: #fff;
  transform:rotate(-45deg) scale(0.6);
}
.main-timeline .timeline-icon{
  color: #fff;
  background: linear-gradient(to right,#008cba,#007da6);
  font-size: 23px;
  text-align: center;
  line-height: 40px;
  height: 40px;
  width: 40px;
  transform: translateY(-50%) rotate(-45deg);
  position: absolute;
  top: 50%;
  left: -20px;
}
.main-timeline .timeline-icon i{ transform: rotate(45deg); }
.main-timeline .timeline-year{
  color: #fff;
  background: linear-gradient(to bottom,#008cba,#008cba,#007da6);
  font-size: 32px;
  font-weight: 900;
  text-align: center;
  line-height: 100px;
  height: 100%;
  width: 100px;
  position: absolute;
  right: 0;
  top: 0;
}
.main-timeline .timeline-year:after{
  content: '';
  background: linear-gradient(to right bottom,#008cba 49%, transparent 50%);
  height: 33px;
  width: 33px;
  position: absolute;
  right: -33px;
  top: 0;
}
.main-timeline .title{
  color: #008cba;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 7px 0;
}
.main-timeline .description{
  color: whitesmoke;
  font-size: 15px;
  margin: 0;
  text-align: left;
}
.main-timeline .timeline:nth-child(even) .description{
  text-align: right;
}
.main-timeline .timeline:nth-child(even){
  padding: 0 27px 0 35px;
  margin: 0 0 20px 10px;
  float: right;
}
.main-timeline .timeline:nth-child(even) .timeline-content{ padding: 15px 40px 15px 115px; }
.main-timeline .timeline:nth-child(even) .timeline-content:before,
.main-timeline .timeline:nth-child(even) .timeline-content:after{
  right: auto;
  left: -58px;
}
.main-timeline .timeline:nth-child(even) .timeline-icon{
  left: auto;
  right: -20px;
}
.main-timeline .timeline:nth-child(even) .timeline-year{
  right: auto;
  left: 0;
}
.main-timeline .timeline:nth-child(even) .timeline-year:after{
  transform: rotateY(180deg);
  right: auto;
  left: -33px;
}
.main-timeline .timeline:nth-child(4n+2) .timeline-content:before,
.main-timeline .timeline:nth-child(4n+2) .timeline-icon {background:linear-gradient(to left,#1c1c1c,#2e2e2e);}
.main-timeline .timeline:nth-child(4n+2) .timeline-year {background:linear-gradient(to bottom,#1c1c1c,#1c1c1c,#2e2e2e);}
.main-timeline .timeline:nth-child(4n+2) .timeline-year:after{background:linear-gradient(to right bottom, #1c1c1c 49%, transparent 50%);}
.main-timeline .timeline:nth-child(4n+2) .title {color: #1c1c1c}
.main-timeline .timeline:nth-child(4n+3) .timeline-content:before,
.main-timeline .timeline:nth-child(4n+3) .timeline-icon {background:linear-gradient(to bottom,#E3BC55 ,#ecc765);}
.main-timeline .timeline:nth-child(4n+3) .timeline-year {background:linear-gradient(to bottom,#E3BC55,#E3BC55,#ecc765);}
.main-timeline .timeline:nth-child(4n+3) .timeline-year:after{background:linear-gradient(to right bottom, #E3BC55 49%, transparent 50%);}
.main-timeline .timeline:nth-child(4n+3) .title {color: #E3BC55}
@media screen and (max-width:767px){
  .main-timeline .description {
    text-align: center;
  }
  .main-timeline .timeline:nth-child(even) .description{
    text-align: center;
  }
  .main-timeline:before{
    left: 17px;
    transform: translateX(0);
  }
  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even){
    width: 100%;
    padding: 0 0 27px 40px;
    margin: 0 0 30px 0;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content{
    padding: 70px 15px 35px 15px;
  }
  .main-timeline .timeline-content:before,
  .main-timeline .timeline-content:after,
  .main-timeline .timeline:nth-child(even) .timeline-content:before,
  .main-timeline .timeline:nth-child(even) .timeline-content:after{
    height: 25px;
    width: 25px;
    top: 10px;
    right: auto;
    left: -34px;
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon{
    transform: translateY(0) translateX(50%) rotate(-45deg);
    left: auto;
    right: 50%;
    top: auto;
    bottom: -20px;
  }
  .main-timeline .timeline-year,
  .main-timeline .timeline:nth-child(even) .timeline-year{
    width: 100%;
    height: 50px;
    line-height: 50px;
    right: auto;
    left: 0;
  }
  .main-timeline .timeline-year:after,
  .main-timeline .timeline:nth-child(even) .timeline-year:after{
    height: 20px;
    width: 20px;
    transform: rotateY(180deg);
    right: auto;
    left: -20px;
  }
}
@media screen and (max-width:576px){
  .main-timeline .title{ font-size: 18px; }
  .main-timeline .description {
    text-align: center;
  }
  .main-timeline .timeline:nth-child(even) .description{
    text-align: center;
  }
}
</style>
