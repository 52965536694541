<template>
  <div class="container-fluid banners">
      <img id="large" src="@/assets/desktop-2.png" alt="Cover"/>
      <img id="mobile" src="@/assets/desktop-mobile.png" alt="Cover"/>
  </div>

  <div class="container pt-5">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center">{{ $t('translations.pools.title') }}</h2>
      </div>
    </div>
  </div>

  <div class="container text-center p-4">
    <span id="main-text" class="text-center">
      <span v-highlight-aqua-royal="$t('translations.pools.description')"></span>
    </span>
  </div>

  <div class="container-fluid p-4">
    <div class="row d-flex justify-content-center">

      <div class="pool-card m-2">
        <h3>{{ $t('translations.pools.familyModel.title') }}</h3>
        <div class="pool-card-image" style="box-shadow: none; background: transparent;">
          <img id="family" src="@/assets/pools/Family/Family-Card.png" alt="Family"
               style="width: 70%; height: 70%; object-fit: cover;"/>
        </div>
        <div class="pool-card-overlay">
          <h3>{{ $t('translations.pools.familyModel.title') }}</h3>
          <span>
              {{ $t('translations.pools.familyCard') }}
            </span>
          <div class="pool-card-link">
            <router-link @click="scrollToTop" to="/pools/family">
              <p style="color: rgb(104, 158, 219);" class="project-details">{{ $t('translations.common.buttons.clickForDetails') }}</p>
            </router-link>
          </div>
        </div>
      </div>

      <div class="pool-card m-2">
        <h3>{{ $t('translations.pools.candyModel.title') }}</h3>
        <div class="pool-card-image" style="box-shadow: none; background: transparent;">
          <img id="family" src="@/assets/pools/Candy/Candy-Card.png" alt="Candy"
               style="width: 80%; height: 80%; object-fit: cover;"/>
        </div>
        <div class="pool-card-overlay">
          <h3>{{ $t('translations.pools.candyModel.title') }}</h3>
          <span>
              {{ $t('translations.pools.candyCard') }}
          </span>
          <div class="pool-card-link">
            <router-link @click="scrollToTop" to="/pools/candy">
              <p style="color: rgb(104, 158, 219);" class="project-details">{{ $t('translations.common.buttons.clickForDetails') }}</p>
            </router-link>
          </div>
        </div>
      </div>

      <div class="pool-card m-2">
        <h3>{{ $t('translations.pools.relaxSpa.title') }}</h3>
        <div class="pool-card-image" style="box-shadow: none; background: transparent;">
          <img id="family" src="@/assets/pools/Relax-Spa/Relax-Spa-Card.png" alt="Relax Spa"
               style="width: 80%; height: 80%; object-fit: cover;"/>
        </div>
        <div class="pool-card-overlay">
          <h3>{{ $t('translations.pools.relaxSpa.title') }}</h3>
          <span>
              {{ $t('translations.pools.relaxSpaCard') }}
          </span>
          <div class="pool-card-link">
            <router-link @click="scrollToTop" to="/pools/relax-spa">
              <p style="color: rgb(104, 158, 219);" class="project-details">{{ $t('translations.common.buttons.clickForDetails') }}</p>
            </router-link>
          </div>
        </div>
      </div>

      <div class="pool-card m-2">
        <h3>{{ $t('translations.pools.sunbed.title') }}</h3>
        <div class="pool-card-image" style="box-shadow: none; background: transparent;">
          <img id="family" src="@/assets/pools/Sunbed/Sunbed-Card.png" alt="Sunbed Bazen"
               style="width: 90%; height: 90%; object-fit: cover;"/>
        </div>
        <div class="pool-card-overlay">
          <h3>{{ $t('translations.pools.sunbed.title') }}</h3>
          <span>
              {{ $t('translations.pools.sunbedCard') }}
          </span>
          <div class="pool-card-link">
            <router-link @click="scrollToTop" to="/pools/sunbed">
              <p style="color: rgb(104, 158, 219);" class="project-details">{{ $t('translations.common.buttons.clickForDetails') }}</p>
            </router-link>
          </div>
        </div>
      </div>

    </div>

    <div class="container mt-5 d-flex justify-content-center align-items-center">
      <button @click="openImage" style="z-index: 1;" class="btn btn-lg btn-primary d-flex align-items-center">
        {{ $t('translations.common.buttons.galleryLookup') }}
        <span class="material-symbols-outlined m-2">photo_library</span>
      </button>
    </div>

    <div class="container text-center p-4">
    <span id="main-text" class="text-center">
      <span v-highlight-aqua-royal="$t('translations.pools.text1')"></span>
    </span>
    </div>

    <div class="container p-4">
      <ul>
        <li><span>{{ $t('translations.pools.bulletin1') }}</span></li>
        <li><span>{{ $t('translations.pools.bulletin2') }}</span></li>
        <li><span>{{ $t('translations.pools.bulletin3') }}</span></li>
        <li><span>{{ $t('translations.pools.bulletin4') }}</span></li>
        <li><span>{{ $t('translations.pools.bulletin5') }}</span></li>
        <li><span>{{ $t('translations.pools.bulletin6') }}</span></li>
      </ul>
    </div>

    <div class="container text-center p-4">
    <span id="main-text" class="text-center">
      <span v-highlight-aqua-royal="$t('translations.pools.text2')"></span>
    </span>
    </div>

  </div>

  <div style="height: 200px"></div>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
export default {
  data() {
    return {
      options: {
        dataSource: [
          {
            src: require('@/assets/gallery/1.jpg'),
            width: 1080,
            height: 1080,
          },
          {
            src: require('@/assets/gallery/2.jpg'),
            width: 1080,
            height: 1080,
          },
          {
            src: require('@/assets/gallery/3.jpg'),
            width: 1080,
            height: 920,
          },
          {
            src: require('@/assets/gallery/4.jpg'),
            width: 1080,
            height: 1080,
          },
          {
            src: require('@/assets/gallery/5.jpg'),
            width: 1074,
            height: 1074,
          },
          {
            src: require('@/assets/gallery/6.jpg'),
            width: 1080,
            height: 1080,
          },
          {
            src: require('@/assets/gallery/7.jpg'),
            width: 1080,
            height: 1080,
          },
          {
            src: require('@/assets/gallery/8.jpg'),
            width: 1080,
            height: 1079,
          },
          {
            src: require('@/assets/gallery/9.jpg'),
            width: 989,
            height: 864,
          },
          {
            src: require('@/assets/gallery/10.jpg'),
            width: 1080,
            height: 1080,
          },
          {
            src: require('@/assets/gallery/11.jpg'),
            width: 1600,
            height: 1200,
          },
        ],
        showHideAnimationType: 'none',
        pswpModule: () => import('photoswipe'),
      }
    };
  },
  methods: {
    openImage() {
      const lightbox = new PhotoSwipeLightbox(this.options);
      lightbox.init();
      lightbox.loadAndOpen(0);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  directives: {
    'highlight-aqua-royal': {
      mounted(el, binding) {
        function highlightText() {
          if (binding.value) {
            el.innerHTML = binding.value.replace(/Aqua Royal/g, '<span style="color: #E3BC55;">Aqua Royal</span>');
          }
        }
        highlightText();
      },
      updated(el, binding) {
        function highlightText() {
          if (binding.value !== binding.oldValue) {
            el.innerHTML = binding.value.replace(/Aqua Royal/g, '<span style="color: #E3BC55;">Aqua Royal</span>');
          }
        }
        highlightText();
      }
    }
  },
  name: 'PoolsComponent'
}
</script>

<style scoped>
/* You can also add custom styles here */

.pool-card:hover .pool-card-overlay {
  opacity: 1;
  background-color: rgba(20,20,20, 0.85);
  cursor: pointer;
}

.pool-card:hover h3 {
  color: white;
}

.pool-card h3 {
  color: #80e2ff;
}

.project-details {
  font-size: 12px;
  font-weight: 300;
  color: #5c73f7;
  padding: 0;
  vertical-align: bottom;
}

.project-details:hover {
  text-decoration: underline;
}

.pool-card-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: .5s ease;
  background-color: black;
  border: 0.3px solid white;
  padding: 20px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.pool-card-link {
  color: #f2f2f2;
  font-size: 15px;
  position: absolute;
  bottom: 2px;
  width: 90%;
  text-align: center;
}

.pool-card-overlay a {
  padding: 0;
  margin: 0;
}

.pool-card {
  background-color: black;
  box-shadow: 0 0 2px 0 rgba(0,12,32,0.04), 0 10px 16px 0 rgba(10,31,68,0.06);
  padding: 20px;
  position: relative;
  width: 20rem;
  height: 270px;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  background-image: url('@/assets/pools/pool_background.png'); background-repeat: no-repeat; background-size: cover;
}

.pool-card p {
  padding: 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  margin: 25px auto auto;
  line-height: 1.8;
}

.pool-card span {
  margin-top: 25px;
  font-size: 14px;
  color: white;
}

.pool-card i {
  color: #ffffff;
}

.pool-card small {
  color: #8da2b5;
  display: inline-block;
}

.pool-card-image {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: black;
  padding: 5px;
  box-shadow: 0 0 1px 0 rgba(0,12,32,0.04), 0 10px 16px 0 rgba(10,31,68,0.06);
  text-align: center;
}

.pool-card-image:hover {
  opacity: 0.7;
  cursor: pointer;
}

.pool-card-image:hover {
  display: flex;
}

.portfolio-text p {
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}


/**********************************************************************************************************************/

body {
  background: radial-gradient(rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
}

.banners {
  padding: 0;
  margin-top: -100px;
}

li::marker {
  color: #E3BC55;
}

h2 {
  color: #E3BC55;
}

span {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.55);
}

#large {
  display: none;
}

#mobile {
  display: none;
}

@media screen and (max-width:576px){
  #large {
    display: none;
  }
  #mobile {
    display: initial;
  }
}

@media screen and (min-width:576px) and (max-width:1920px){
  #large {
    display: initial;
  }
  #mobile {
    display: none;
  }
}

@media (min-width: 1920px) and (max-width:3540px){
  #large {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  #mobile {
    display: none;
  }
}
</style>
