<template>
  <div class="container pt-5">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center">{{ $t('translations.aboutUs.title') }}</h2>
      </div>
    </div>
  </div>
  <div class="container mt-1 mb-5" id="services">
    <div class="row d-flex justify-content-center">
      <div v-for="(card, index) in cards" :key="index" class="col-lg-3 col-6">
        <div class="card bg-dark text-center m-1 service-card" style="z-index: 1">
          <div class="card-body p-1 d-flex flex-column justify-content-center align-items-center">
            <p class="card-stat">{{ card.cardStat }} {{ card.unit }}</p>
            <p class="card-text">{{ card.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span v-highlight-text="$t('translations.aboutUs.text1')"></span>
    </span>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span v-highlight-text="$t('translations.aboutUs.text2')"></span>
    </span>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span v-highlight-text="$t('translations.aboutUs.text3')"></span>
    </span>
  </div>
  <div style="height: 200px"></div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        { cardStat: 1, targetValue: 50, text: this.$t('translations.aboutUs.poolsManufactured'), unit: '+' },
        { cardStat: 1, targetValue: 5, text: this.$t('translations.aboutUs.manufacturingArea'), unit: 'ha' },
        { cardStat: 1, targetValue: 5, text: this.$t('translations.aboutUs.countries'), unit: '' },
      ]
    };
  },
  mounted() {
    this.incrementCardStats();
  },
  methods: {
    incrementCardStats() {
      const delay = 100; // milliseconds

      const increment = (card) => {
        if (card.cardStat < card.targetValue) {
          setTimeout(() => {
            card.cardStat++;
            increment(card);
          }, delay);
        }
      };

      this.cards.forEach(card => {
        increment(card);
      });
    }
  },
  directives: {
    'highlight-text': {
      mounted(el, binding, vnode) {
        let text = binding.value;
        const replacementRules = {
          'Aqua Royal': { color: '#E3BC55' },
          'Aqua Harmony': { color: '#008cba' }
        };
        for (const key in replacementRules) {
          if (Object.prototype.hasOwnProperty.call(replacementRules, key)) {
            const replacement = replacementRules[key];
            const regex = new RegExp(key, 'g');
            // Replace occurrences of the key with the specified color
            if (key === 'Aqua Harmony') {
              text = text.replace(regex, `<a href="https://aquaharmony-fiberglass.com/" target="_blank" style="text-decoration: none; color: ${replacement.color};">${key}</a>`);
            } else {
              text = text.replace(regex, `<span style="color: ${replacement.color};">${key}</span>`);
            }
          }
        }
        el.innerHTML = text;
      }
    }
  },
  name: 'AboutUsComponent'
}
</script>

<style scoped>
/* You can also add custom styles here */
body {
  background: radial-gradient(rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
}

.container-fluid {
  padding: 30px;
}

h2 {
  color: #E3BC55;
}

.service-icon {
  font-size: 50px;
}

#main-text {
  font-size: 20px;
  font-family: sans-serif;
  color: rgba(255, 255, 255, 0.55);
}

.service-card {
  height: 150px;
  color: white;
}

.service-card:hover {
  cursor: pointer;
  color: #E3BC55;
}

.card-stat {
  font-size: 3rem;
}
</style>
