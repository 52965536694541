import AboutUsComponent from "@/components/common/AboutUsComponent.vue";
import ContactUsComponent from "@/components/common/ContactUsComponent.vue";
import HomeComponent from "@/components/common/HomeComponent.vue";
import CandyComponent from "@/components/common/pools/CandyComponent.vue";
import FamilyComponent from "@/components/common/pools/FamilyComponent.vue";
import RelaxSpaComponent from "@/components/common/pools/RelaxSpaComponent.vue";
import SunbedComponent from "@/components/common/pools/SunbedComponent.vue";
import PoolsComponent from "@/components/common/PoolsComponent.vue";
import TechnologyComponent from "@/components/common/TechnologyComponent.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: { name: 'home' }
  }, {
    path: '/',
    name: 'home',
    component: HomeComponent
  }, {
    path: '/home',
    name: 'home',
    component: HomeComponent,
  }, {
    path: '/pools',
    name: 'Pools',
    component: PoolsComponent,
  }, {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUsComponent,
  }, {
    path: '/technology',
    name: 'Technology',
    component: TechnologyComponent,
  }, {
    path: '/about-us',
    name: 'About Us',
    component: AboutUsComponent,
  }, {
    path: '/pools/family',
    name: 'Family',
    component: FamilyComponent,
  }, {
    path: '/pools/candy',
    name: 'Candy',
    component: CandyComponent,
  }, {
    path: '/pools/relax-spa',
    name: 'Relax Spa',
    component: RelaxSpaComponent,
  }, {
    path: '/pools/sunbed',
    name: 'Sunbed',
    component: SunbedComponent,
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
