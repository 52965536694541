<template>
  <div class="container pt-5">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center">{{ $t('translations.pools.relaxSpa.title') }}</h2>
      </div>
    </div>
  </div>
  <div class="container mt-5 text-center">
    <span v-highlight-aqua-royal="$t('translations.pools.relaxSpa.description')" id="main-text" class="text-center"></span>
  </div>
  <div class="container mt-3 text-center">
    <div class="row">
      <div class="col">
        <img class="pool-dimensions" src="@/assets/pools/Relax-Spa/Relax-Spa-Dimensions.png" alt="Relax-Spa Dimensions"/>
      </div>
    </div>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span  v-highlight-aqua-royal="$t('translations.pools.relaxSpa.text1')"></span>
    </span>
  </div>
  <div class="container mt-3 text-center">
    <div class="row d-flex justify-content-between align-items-center">
      <div class="col-6">
        <img src="@/assets/pools/Relax-Spa/Relax-Spa.png" alt="Relax-Spa"
             style="height: 300px;"/>
      </div>
      <div class="col-6">
        <img class="pool-shadow" src="@/assets/pools/Relax-Spa/Relax-Spa-3.png" alt="Relax-Spa"
             style="height: 200px;"/>
      </div>
    </div>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span  v-highlight-aqua-royal="$t('translations.pools.relaxSpa.text2')"></span>
    </span>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span  v-highlight-aqua-royal="$t('translations.pools.relaxSpa.text3')"></span>
    </span>
  </div>
  <div class="container mt-1 text-center">
    <div class="row d-flex justify-content-between align-items-center">
      <div class="col-6">
        <img src="@/assets/pools/Relax-Spa/Relax-Spa-3-W.png" alt="Relax-Spa"
             style="max-height: 200px;"/>
      </div>
      <div class="col-6">
        <img class="pool-shadow" src="@/assets/pools/Relax-Spa/Relax-Spa-W.png" alt="Relax-Spa"
             style="max-height: 300px;"/>
      </div>
    </div>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span  v-highlight-aqua-royal="$t('translations.pools.relaxSpa.text4')"></span>
    </span>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span  v-highlight-aqua-royal="$t('translations.pools.relaxSpa.text5')"></span>
    </span>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span  v-highlight-aqua-royal="$t('translations.pools.relaxSpa.text6')"></span>
    </span>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span  v-highlight-aqua-royal="$t('translations.pools.relaxSpa.text7')"></span>
    </span>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span  v-highlight-aqua-royal="$t('translations.pools.relaxSpa.text8')"></span>
    </span>
  </div>
  <div class="container mt-5 text-center">
    <span id="main-text" class="text-center">
      <span  v-highlight-aqua-royal="$t('translations.pools.relaxSpa.text9')"></span>
    </span>
  </div>
  <div style="height: 200px"></div>
</template>

<script>
export default {
  directives: {
    'highlight-aqua-royal': {
      mounted(el, binding, vnode) {
        let text = binding.value;
        const replacementRules = {
          'Aqua Royal': { color: '#E3BC55' },
          'Relax Spa': { color: '#E3BC55' }
        };
        for (const key in replacementRules) {
          if (Object.prototype.hasOwnProperty.call(replacementRules, key)) {
            const replacement = replacementRules[key];
            const regex = new RegExp(key, 'g');
            // Replace occurrences of the key with the specified color
            text = text.replace(regex, `<span style="color: ${replacement.color};">${key}</span>`);
          }
        }
        el.innerHTML = text;
      }
    }
  },
  name: 'RelaxSpaComponent'
}
</script>

<style scoped>
/* You can also add custom styles here */
body {
  background: radial-gradient(rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
}

h2 {
  color: #E3BC55;
}

#main-text {
  font-size: 20px;
  font-family: sans-serif;
  color: rgba(255, 255, 255, 0.55);
}

.aqua-royal {
  color: #E3BC55;
}

.pool-shadow {
  filter: drop-shadow(-19px 5px 10px #000000);
}

.pool-dimensions {
  max-width: 480px;
}

@media screen and (max-width:576px){
  .pool-dimensions {
    max-width: 320px;
  }
}
</style>
