import i18n from "@/i18n";
import {getLanguageFromStorage} from "@/utils/utils";
import { createApp } from 'vue'
import LoadScript from "vue-plugin-load-script";
import App from './App.vue'
import router from './router'
import "bootstrap";
import "../node_modules/bootswatch/dist/yeti/bootstrap.min.css";
import "jquery";
import "popper.js"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB0Dr5TNJapx65MiGIdlDMkdkdc6frMokQ",
    authDomain: "aquaroyal.firebaseapp.com",
    projectId: "aquaroyal",
    storageBucket: "aquaroyal.appspot.com",
    messagingSenderId: "236088448123",
    appId: "1:236088448123:web:fbd3f2eecd89c30416fede",
    measurementId: "G-DPV5EYM0SG"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const locale = getLanguageFromStorage();
if (locale && (locale === "me" || locale === 'ru' || locale === 'en')) {
    console.log("Selected language: " + locale.toUpperCase());
    i18n.global.locale = locale;
}

const app = createApp(App);
app.use(i18n);
app.use(LoadScript);
app.use(router).mount('#app')