<template>
  <div class="footer-wrapper">
    <div class="ocean">
      <div class="wave"></div>
      <div class="wave wave2"></div>
    </div>
    <div class="text-overlay">
      <p class="m-0">Aqua Royal © 2024</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
.footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.ocean {
  position: relative;
  height: 5%;
  width: 100%;
  background: #015871;
}

.wave {
  background: url('@/assets/footer/wave.svg') repeat-x;
  position: absolute;
  width: 6400px;
  top: -198px;
  left: 0;
  height: 198px;
  transform: translate(0, 0, 0);
  animation: wave 7s ease infinite;
}

.wave:nth-of-type(2) {
  top: -168px;
  animation: swell 4s ease infinite;
  opacity: 1;
}

.text-overlay {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; /* Ensure text is above waves */
  color: white; /* Text color */
}

@keyframes wave {
  0% { margin-left: 0; }
  100% { margin-left: -1600px;}
}

@keyframes swell {
  0%, 100% {
    transform: translate(0, -30px);
  }
  50% {
    transform: translate(0, 5px);
  }
}
</style>
