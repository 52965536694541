<template>
  <nav class="navbar navbar-expand-lg bg-dark fixed-top" data-bs-theme="dark">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation" ref="navbarToggler">
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link class="navbar-brand" to="/home">
        <img src="@/assets/logo.png" alt="Aqua Royal Logo"/>
      </router-link>
      <div class="collapse navbar-collapse" id="navbarColor02" ref="navbar">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link @click="scrollToTop" class="nav-link" to="/home">{{ $t('translations.common.navBar.home') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link @click="scrollToTop" class="nav-link" to="/pools">{{ $t('translations.common.navBar.pools') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link @click="scrollToTop" class="nav-link" to="/technology">{{ $t('translations.common.navBar.technology') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link @click="scrollToTop" class="nav-link" to="/about-us">{{ $t('translations.common.navBar.aboutUs') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link @click="scrollToTop" class="nav-link" to="/contact-us">{{ $t('translations.common.navBar.contactUs') }}</router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item dropdown" id="language-select">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ $t('translations.common.navBar.language') }}
            </a>
            <div class="dropdown-menu dropdown-menu-end text-center" aria-labelledby="navbarDropdown">
              <a class="dropdown-item large text-center" href="#" @click="setLanguage('me')">ME <span class="fi fi-me"></span></a>
              <a class="dropdown-item large text-center" href="#" @click="setLanguage('ru')">РУ <span class="fi fi-ru"></span></a>
              <a class="dropdown-item large text-center" href="#" @click="setLanguage('en')">EN <span class="fi fi-gb"></span></a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>



<script>
import {saveLanguageToStorage} from "@/utils/utils";

export default {
  name: 'NavBarComponent',
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
      saveLanguageToStorage(lang);
      const navbarIsOpen = this.$refs.navbar.classList.contains('show');
      if (navbarIsOpen) {
        this.$refs.navbarToggler.click();
      }
      window.location.reload();
    },
    scrollToTop() {
      const navbarIsOpen = this.$refs.navbar.classList.contains('show');
      if (navbarIsOpen) {
        this.$refs.navbarToggler.click();
      }
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>
/* You can also add custom styles here */
.nav-link {
  font-size: x-large;
}

#language-select {
  border-bottom: solid 1px;
  font-size: small;
}

.large {
  font-size: x-large;
}
</style>
